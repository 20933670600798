<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Corte diario
            <v-spacer></v-spacer>

            <!-- VALIDAR LOS PAGOS -->
            <v-btn class="mr-2" small dark color="green" @click="dialogPlanteles = true" tile v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto)">
              <v-icon small>mdi-check</v-icon> Validar
            </v-btn>

            <!-- EXPORTAT EL EXCEL -->
            <v-btn class="mr-2" small dark color="green" @click="dialogIngresosCorte.estatus = true" tile v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto) && !validaPagos">
              <v-icon small>mdi-auto-download</v-icon>
            </v-btn>

            <!-- EXPORTAT EL EXCEL -->
            <v-btn class="mr-2" small dark color="green" @click="exportar( tablaMovimientos )" tile v-if="!validaPagos">
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- EXPORTAT EL EXCEL -->
            <v-btn class="mr-2" small dark color="green" @click="exportar2( tablaMovimientos )" tile v-if="!validaPagos">
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- IMPRIMIR CORTE  -->
            <v-btn
              color="orange"
              class="mr-2"
              @click="generarRecibo( )"
              small
              dark
              tile
              v-if="!validaPagos"
            >
              <v-icon small left>mdi-cash-register</v-icon>
              CORTE
            </v-btn>

            <!-- DIFERENCIAS  -->
            <v-btn
              color="secondary"
              class="mr-2"
              @click="dialogCifras = true"
              small
              dark
              v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto)"
              tile
              ><v-icon left>mdi-currency-usd</v-icon>Diferencias</v-btn
            >

            <!-- ACTUALIZAR LA INFO -->
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="consultar()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            
          </v-card-title>

          <!-- CONTENIDO -->
          <v-card-text class="">

            <!-- FILTROS  -->
            <v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  :clearable="[18, 19, 17].includes( getdatosUsuario.idpuesto) ? false : true "
                  v-model="plantel"
                  :items="planteles"
                  label="Selecciona un plantel"
                  persistent-hint
                  hide-details
                  item-text="plantel"
                  item-value="id_plantel"
                  :readonly="[18, 19, 17].includes( getdatosUsuario.idpuesto) ? true : false "
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_corte"
                  type="date"
                  @keyup.enter="consultar"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                lg="4"
                xl="4"
                v-if="plantel && fecha_corte && miCorte"
              >
                <v-card width="44" v-if="[1,2].includes( miCorte.estatus )" @click="verMotivoError = true, motivoError = miCorte.motivo">
                  <v-avatar
                    size="44"
                    :color="miCorte.estatus == 2 ? 'red' : 'green'"
                    rounded
                    class="elevation-1"
                  >
                    <v-icon
                      dark
                      color="white"
                      size="30"
                      v-if="miCorte.estatus == 1"
                    >
                      mdi-check
                    </v-icon>

                    <v-icon dark color="white" size="30" v-else-if="miCorte.estatus == 2">
                      mdi-close
                    </v-icon>
                  </v-avatar>
                </v-card>
                
                <v-chip color="blue"  dark v-else-if="miCorte.estatus == 0" >Pendiente</v-chip>
              </v-col>
            </v-row>

            <!-- Alerta para indicar que hay pagos con datos incompletos -->
            <v-row v-if="validaPagos">
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="error"
                >
                  Tienes <strong>PAGOS</strong> con <strong>Tarjeta o Deposito</strong> sin datos completos
                </v-alert>
              </v-col>
            </v-row>

            <!-- Alerta de pagos rechazados -->
            <v-row v-if="pagosRechazados">
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="error"
                >
                  Tienes <strong>PAGOS</strong> con estatus <strong>RECHAZADOS</strong> Favor de validarlos en: <strong>"VALIDAR PAGOS SUCURSAL"</strong>
                </v-alert>
              </v-col>
            </v-row>


            <v-row align="center">

              <!-- DESGLOSE DE LOS PAGOS; SOLO PARA VENDEDORAS -->
              <v-col cols="12" v-if="plantel" class="pa-0">
                <v-card class="transparent elevation-0">
                  <v-card-text class="black--text text-subtitle-1" v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto) && !validaPagos">
                    <b>Corte plantel: </b> 
                      {{ corte_plantel }}
                    <br/>
                    <b>Diferencia global: </b> 
                      $ {{ diferencia_global }}
                  </v-card-text>

                  <v-card-text class="black--text text-h6" v-else-if="!validaPagos">
                    <b>Corte plantel: </b> 
                      {{ corte_plantel }}
                    <br/>
                    <v-btn @click="expand = !expand" color="primary" small v-if="!validaPagos">
                      {{ !expand ? 'Reporte completo' : 'Ocultar Reporte' }}
                    </v-btn>
                  </v-card-text>

                  <v-expand-transition>
                    <div v-if="expand">
                      <v-card max-width="500" class="shadowCard">
                        <v-list class="transparent">
                          <v-list-item>
                            <v-list-item-title>Diferencia</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ diferencia_total }}
                            </v-list-item-subtitle>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-title>Pagos de tu suc. a otra suc.</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ pagos_otra_suc }}
                            </v-list-item-subtitle>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-title>Pagos de otra suc. a tu suc.</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ pagos_de_otra_suc }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-list>

                        <v-list-item>
                            <v-list-item-title>Pagos propios.</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ pagos_propios }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-list>
                      </v-card>

                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>

              <v-col cols="12" v-if="plantel && !validaPagos" class="pa-0">
                <MisMovimientos :tablaPagosSucursal="tablaPagosSucursal" :plantel="plantel" :url_servidor="url_servidor"/>
              </v-col>

              <v-col cols="12" v-if="plantel && !validaPagos" class="pa-0">
                <MovimientosOtraSucursal  :pagosDesdeOtraSucursal="pagosDesdeOtraSucursal" :plantel="plantel" :url_servidor="url_servidor"/>
              </v-col>

              <v-col cols="12" class="pa-0" v-if="!validaPagos">
                <MovimientosCorte :num_pagos="num_pagos" :desglose="desglose" :cambios="cambios" :url_servidor="url_servidor" :tablaMovimientos="tablaMovimientos" :plantel="plantel" @consultar="consultar"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- dialog para diferencias -->
    <v-dialog
      v-model="dialogCifras"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title>
          Cifras
          <v-spacer></v-spacer>
          <v-btn small dark color="green" @click="exportar(filterCifras)">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-radio-group v-model="mostrar_cifras_completas" row>
                <v-radio label="Diferencias" :value="1"></v-radio>
                <v-radio label="Todas" :value="2"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="ciclo"
                :items="ciclosUnicos"
                label="Selecciona el ciclo"
                persistent-hint
                hide-details
                item-text="ciclo"
                item-value="id_ciclo"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersCifras"
            :items="filterCifras"
            class="elevation-0"
            dense
            mobile-breakpoint="200"
          >
            <template v-slot:item.diferencia="{ item }">
              {{ item.diferencia.toFixed(2) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCifras = false" small color="green" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para ver la imagen -->
    <v-dialog
      v-model="dialgImagen"
      max-width="600px"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-img
              :src="url_servidor + '/comprobante-pago/' + imagen"
              contain
              max-width="600"
              height="800"
            >
            </v-img>
          </v-col>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialgImagen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn
        >
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

    <IngresosCorte v-if="dialogIngresosCorte.estatus" :dialogIngresosCorte="dialogIngresosCorte" />

    <v-dialog
      v-model="dialogPlanteles"
      max-width="800px"
    >
      <v-card class="elevation-0">
        <v-card-title primary-title>
          Validación de corte
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :headers="headersValidar"
            :items="plantelesInbi"
            class="elevation-1"
            :items-per-page="plantelesInbi.length"
            hide-default-footer
          >
            <template v-slot:item.estatus="{ item }">
              <v-chip v-if="item.estatus == 0" dark small color="blue">Pendiente</v-chip>
              <v-chip v-if="item.estatus == 1" dark small color="green">Aceptado</v-chip>
              <v-chip v-if="item.estatus == 2" dark small color="red">Rechazadp</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn tile x-small color="green" dark class="mt-2" @click="aceptarCorte( item )"     v-if="item.pagossindatos == 0"><v-icon small>mdi-check</v-icon></v-btn>
              <v-btn tile x-small color="red"   dark class="mt-2" @click="confirmarRechazo( item )" v-if="item.pagossindatos == 0"><v-icon small>mdi-close</v-icon></v-btn>
            </template>
          </v-data-table>

          <v-divider class="my-2"></v-divider>

          <v-data-table
            dense
            :headers="headersValidar"
            :items="plantelesFast"
            class="elevation-1"
            :items-per-page="plantelesFast.length"
            hide-default-footer
          >
            <template v-slot:item.estatus="{ item }">
              <v-chip v-if="item.estatus == 0" dark small color="blue">Pendiente</v-chip>
              <v-chip v-if="item.estatus == 1" dark small color="green">Aceptado</v-chip>
              <v-chip v-if="item.estatus == 2" dark small color="red">Rechazadp</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn tile x-small color="green" dark class="mt-2" @click="aceptarCorte( item )    " v-if="item.pagossindatos == 0"><v-icon small>mdi-check</v-icon></v-btn>
              <v-btn tile x-small color="red"   dark class="mt-2" @click="confirmarRechazo( item )" v-if="item.pagossindatos == 0"><v-icon small>mdi-close</v-icon></v-btn>
            </template>

          </v-data-table>

        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          small
          icon
          @click="dialogPlanteles = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirma"
      max-width="550px"
    >
      <v-card class="elevation-0">
        <v-card-title primary-title>
          Ingresa el motivo de rechazo
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Ingresa el motivo de rechazo"
            filled
            v-model="motivo"
          ></v-text-field>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          small
          icon
          class="elevation-6"
          @click="dialogConfirma = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            tile
            small
            @click="rechazarComprobante( datosPago )"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="verMotivoError"
      max-width="550px"
    >
      <v-card class="elevation-0">
        <v-card-title primary-title>
          Detalle del corte
        </v-card-title>
        <v-card-text>
          <v-textarea
            label="Ingresa el motivo de rechazo"
            filled
            readonly
            :rows="3"
            auto-grow
            v-model="motivoError"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" dark v-if="miCorte && miCorte.estatus == 2" @click="solicitarRevision( )">Solicitar revisión</v-btn>
        </v-card-actions>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          small
          icon
          class="elevation-6"
          @click="verMotivoError = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>

    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';

import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga  from "@/components/alertas/carga.vue";

import MisMovimientos           from "@/components/corte/MisMovimientos.vue";
import MovimientosOtraSucursal  from "@/components/corte/MovimientosOtraSucursal.vue";
import MovimientosCorte         from "@/components/corte/MovimientosCorte.vue";
import IngresosCorte            from "@/components/corte/IngresosCorte.vue";

import validarErrores           from "@/mixins/validarErrores";

import XLSX from "xlsx";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  components: {
    Alerta,
    carga,
    MisMovimientos,
    MovimientosOtraSucursal,
    MovimientosCorte,
    IngresosCorte
  },

  mixins: [ validarErrores ],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    fecha_corte: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    planteles: [],
    plantel: null,
    fullscreen: false, 
    dialogCifras: false,
    url_servidor: null,
    fullscreen: false,
    dialogDiferencias:false,

    url_servidor: null,             //Angel Rodriguez                      
    dialgImagen: false,
    imagen: null,

    snackbar: false,

    headers: [
      { text: "id"           , value: "id_plantel" },
      { text: "Plantel"      , value: "plantel"      , align: "right" },
      { text: "Efectivo"     , value: "efectivo"     , align: "right" },
      { text: "Transferencia", value: "transferencia", align: "right" },
      { text: "Depositos"    , value: "depositos"    , align: "right" },
      { text: "Tarjeta"      , value: "tarjeta"      , align: "right" },
      { text: "Total"        , value: "total"        , align: "right" },
    ],

    headersCifras: [
      { text: "id"           , value: "id_ciclo"      },
      { text: "Ciclo"        , value: "ciclo"         },
      { text: "Plantel"      , value: "plantel"       },
      { text: "Cifra inicial", value: "cifra_inicial" },
      { text: "Cifra final"  , value: "cifra_final"   },
      { text: "Diferencia"   , value: "diferencia"    },
    ],

    headersDiferencias: [
      { text: "Plantel"     , value: "plantel"        },
      { text: "ERP viejo"   , value: "cifraERPVIEJO"  },
      { text: "ERP nuevo"   , value: "cifraERPNUEVO"  },
      { text: "Diferencia"  , value: "diferenteMonto" },
    ],

    headersValidar: [
      { text: "Plantel"  , value: "plantel" },
      { text: "Estatus"  , value: "estatus" },
      { text: "Motivo"   , value: "motivo"  },
      { text: "Actions"  , value: "actions" },
    ],

    diferencias:[],

    tablaPagosSucursal: [],
    desglose: [],
    cifras: [],
    pagosDesdeOtraSucursal: [],
    diferencia_total: 0,
    corte_plantel: 0,
    pagos_otra_suc: 0,
    pagos_propios: 0,
    pagos_de_otra_suc: 0,
    diferencia_global: 0,
    cifra_inicial: 0,
    cifra_final: 0,

    mostrar_cifras_completas: 1,
    ciclosUnicos: [],
    ciclo: null,
    searchMovimientos:'',

    expand: false,

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    cicloDiferencia:null,

    parametosComparativa:{
      estatus: false
    },
    cambios:[],
    num_pagos: 0,
    tablaMovimientos:[],
    dialogIngresosCorte: {
      estatus: false
    },
    rechazados:[],
    dialogPlanteles: false,
    plantelesInbi:[],
    plantelesFast:[],
    datosPago: null,
    dialogConfirma: false,
    motivo:'',
    miCorte: null,
    verMotivoError: false,
    motivoError: ''
  }),

  watch: {
    plantel(value) {
      if (value && this.fecha_corte) {
        this.consultar();
      }
    },

    fecha_corte(value) {
      if (value && this.plantel) {
        this.consultar();
      }
    },

    cicloDiferencia( value ){
      if(value){ this.getDiferenciaCiclos() }
    },

  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    filterCifras( ) {
      let cifras = this.cifras;
      
      if (this.mostrar_cifras_completas == 1) {
        cifras = cifras.filter((el) => {
          return el.diferencia > 0;
        });
      }

      if (this.ciclo) {
        cifras = cifras.filter((el) => {
          return el.id_ciclo == this.ciclo;
        });
      }

      return cifras;
    },

    validaPagos( ){

      let data = this.tablaMovimientos

      data = data.filter( el => { return [2,4].includes( el.id_forma_pago ) })

      data = data.filter( el => !el.aut_clave_rastreo || !el.folio_operacion || !el.cuenta )

      if( [1].includes ( this.getdatosUsuario.iderp ) ){ return false }

      return data.length > 0 ? true : false
    },

    pagosRechazados( ){

      let pagos = this.rechazados

      let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);

      // María Esther Segura Canales
      if( this.getdatosUsuario.iderp == 568 ){
        mapIdPlanteles = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,10002,10003,10004] 
      }

      // Sofía Lizeth ARIAS
      if( this.getdatosUsuario.iderp == 626 ){
        mapIdPlanteles = [32,21,4,6] 
      }

      if( this.getdatosUsuario.iderp == 7 ){
        mapIdPlanteles = [29] 
      }

      if( this.getdatosUsuario.iderp == 1312 ){
        mapIdPlanteles = [27,5,31,17] 
      }

      // Patricia Salazar
      if( this.getdatosUsuario.iderp == 161 ){
        mapIdPlanteles = [14,8,12,9,16] 
      }

      // PIEDAD LOPEZ 
      if( this.getdatosUsuario.iderp == 1248 ){
        mapIdPlanteles = [11,7,8,10] 
      }

      // María Isabel Rodríguez Mora
      if( this.getdatosUsuario.iderp == 1196 ){
        mapIdPlanteles = [2,3,5] 
      }

      // fernanda oliva espinosa
      if( this.getdatosUsuario.iderp == 1489 ){
        mapIdPlanteles = [25,28,27,31,17] 
      }

      // PAOLA ZUYIN RODRIGUEZ ZAPATA
      if( this.getdatosUsuario.iderp == 1312 ){
        mapIdPlanteles = [24,1,23,22] 
      }


      if( [18, 19, 17].includes(this.getdatosUsuario.idpuesto) ){

        pagos = pagos.filter( el => mapIdPlanteles.includes( el.id_plantel_ingreso ) )

      }

      return pagos.length > 0 ? true : false
      // return true

    }
  },

  created() {

    if( [18, 19, 17, 39 ].includes(this.getdatosUsuario.idpuesto) ){
      this.plantel = this.getdatosUsuario.id_plantel
    }
    this.initialize();
    this.url_servidor = "https://escuelakpi.club/kpi/";
  },

  methods: {
    initialize() {
      this.cargar = true;
      this.planteles = [];
      this.$http.get("kpi.planteles.all").then((response) => {
        if ([18, 19, 17].includes(this.getdatosUsuario.idpuesto)) {
          this.planteles = response.data.filter((el) => {
            return el.id_plantel == this.getdatosUsuario.id_plantel;
          });
        } else {
          this.planteles = response.data;
        }

        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    consultar() {
      this.tablaPagosSucursal     = [];
      this.desglose               = [];
      this.cifras                 = [];
      this.pagosDesdeOtraSucursal = [];
      this.diferencia_total       = 0;
      this.corte_plantel          = 0;
      this.pagos_otra_suc         = 0;
      this.pagos_propios          = 0;
      this.pagos_de_otra_suc      = 0;
      this.diferencia_global      = 0;
      this.cifra_inicial          = 0;
      this.cifra_final            = 0;
      this.rechazados             = 0;
      this.cargar                 = true;

      var payload = {
        fecha_corte: this.fecha_corte,
        id_plantel: this.plantel,
      };

      this.$http.post("corte.sucursal", payload).then((response) => {
        this.tablaPagosSucursal      = response.data.tablaPagosSucursal;
        this.desglose                = response.data.desglose;
        this.cifras                  = response.data.cifras;
        this.pagosDesdeOtraSucursal  = response.data.pagosDesdeOtraSucursal;
        this.diferencia_total        = response.data.diferencia_total;
        this.corte_plantel           = response.data.corte_plantel;
        this.pagos_otra_suc          = response.data.pagos_otra_suc;
        this.pagos_propios           = response.data.pagos_propios;
        this.pagos_de_otra_suc       = response.data.pagos_de_otra_suc;
        this.diferencia_global       = response.data.diferencia_global;
        this.cifra_inicial           = response.data.cifra_inicial;
        this.cifra_final             = response.data.cifra_final;
        this.ciclosUnicos            = response.data.ciclosUnicos;
        this.cambios                 = response.data.cambios;
        this.num_pagos               = response.data.num_pagos;
        this.tablaMovimientos        = response.data.tablaMovimientos;
        this.rechazados              = response.data.rechazados
        this.plantelesInbi           = response.data.plantelesInbi
        this.plantelesFast           = response.data.plantelesFast
        this.miCorte                 = response.data.miCorte
        this.cargar                  = false;
        console.log( 'inbiii',this.plantelesInbi  )

        this.corte_plantel    = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.corte_plantel )
        this.diferencia_total = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.diferencia_total )
        this.pagos_otra_suc   = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.pagos_otra_suc )
        this.pagos_propios    = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.pagos_propios )


        for( const i in this.tablaPagosSucursal ){
          let { efectivo, depositos, tarjeta, total, transferencia } = this.tablaPagosSucursal[i]
          this.tablaPagosSucursal[i].efectivo      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( efectivo )
          this.tablaPagosSucursal[i].depositos     = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( depositos )
          this.tablaPagosSucursal[i].tarjeta       = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( tarjeta )
          this.tablaPagosSucursal[i].total         = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
          this.tablaPagosSucursal[i].transferencia = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( transferencia )
        }

        for( const i in this.pagosDesdeOtraSucursal ){
          let { efectivo, depositos, tarjeta, total, transferencia } = this.pagosDesdeOtraSucursal[i]
          this.pagosDesdeOtraSucursal[i].efectivo      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( efectivo )
          this.pagosDesdeOtraSucursal[i].depositos     = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( depositos )
          this.pagosDesdeOtraSucursal[i].tarjeta       = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( tarjeta )
          this.pagosDesdeOtraSucursal[i].total         = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
          this.pagosDesdeOtraSucursal[i].transferencia = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( transferencia )
        }

        for( const i in this.tablaMovimientos ){
          let { monto_pagado } = this.tablaMovimientos[i]
          this.tablaMovimientos[i].monto_pagado      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( monto_pagado )
        }

        for( const i in this.desglose ){
          let { desglose, total } = this.desglose[i]

          for( const j in desglose ){
            let { monto_pagado } = desglose[j]
            this.desglose[i].desglose[j].monto_pagado      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( monto_pagado )
          }

          this.desglose[i].total      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
        }

      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },


    aceptarCorte( item ){

      this.cargar = true

      item.estatus = 1

      this.$http.post("aceptar.corte", item).then((response) => {
        this.cargar = false
        this.consultar( )
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });

    },

    solicitarRevision( ){
      this.cargar = true

      this.$http.post("solicitar.revision.corte", this.miCorte).then((response) => {
        this.cargar = false
        this.consultar( )
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    confirmarRechazo( data ){
      this.datosPago = data
      this.dialogConfirma = true
    },

    rechazarComprobante( data ){

      this.cargar = true

      data.estatus = 2
      data.motivo  = this.motivo

      this.$http.post("aceptar.corte", data).then((response) => {
        this.cargar = false
        this.dialogConfirma = false
        this.motivo = ''
        this.consultar( )
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    getDiferenciaCiclos() {

      var payload = {
        id_ciclo: this.cicloDiferencia,
      };

      this.cargar = true

      this.diferencias = []
      this.$http.post("inscripciones.comparaciones.ciclo", payload).then((response) => {
        this.cargar = false
        this.diferencias = response.data;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    abrirDialog(value) {
      console.log(value);
      this.alumnos = value;
      this.dialogCifras = true;
    },

    exportar(value) {
      this.exportExcel(value);
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportExcel(value) {
      let data = XLSX.utils.json_to_sheet(value);
      const workbook = XLSX.utils.book_new();
      const filename = "cifras_" + this.plantel;
      XLSX.utils.book_append_sheet(workbook, data, filename);

      var wbout = XLSX.write(workbook, {
        bookType: "xls",
        bookSST: false,
        type: "binary",
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + ".xls");

      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo
      formData.append("file", file);
      // /*getDatosRiesgo the form data*/

      this.$http
        .post("pdfs", formData)
        .then((response) => {
          window.location =
            axios.defaults.baseURL + "pdfs/" + filename + ".xls";
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    verImagen(value) {            //Angel Rodriguez              
      this.imagen = value;
      this.dialgImagen = true;
    },

    generarRecibo( ){

      const existePlantel = this.planteles.find( el => el.id_plantel == this.plantel )

      var payload = {
        desglose: this.tablaMovimientos,
        montos  : this.tablaPagosSucursal,
        usuario : this.getdatosUsuario.nombre_completo,
        fecha   : this.fecha_corte,
        plantel : existePlantel ? existePlantel.plantel : ''
      };

      this.cargar = true

      this.diferencias = []

      this.$http.post("corte.recibo", payload).then((response) => {

        this.cargar = false

        const url = axios.defaults.baseURL + 'recibo-pago/' + response.data.folio + '.pdf'

        window.open( url )

        this.validarSuccess( response.data.message )

      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    exportar2( ){

      const alumnos = this.tablaMovimientos.map(({ id_ingreso, fecha_pago, monto_pagado, alumno, ciclo, plantel }) => ({ id_ingreso, fecha_pago, monto_pagado, alumno, ciclo, plantel }));

      this.exportExcel( alumnos)

    }
  },
};
</script>
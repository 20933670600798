<template >
	<v-card class="elevation-0" >
		<v-row>

      <v-col cols="12">
      	<v-card class="shadowCard" >
      		<v-card-text class="black--text">
		        <span ><b>Alumno:</b> {{ alumno.nombre }} {{ alumno.apellido_paterno }} {{ alumno.apellido_materno }} <br/></span>
		        <span ><b>Matricula:</b> Sin Matricula <br/></span>
		        <span ><b>Grupo:</b>{{ alumno.grupo.grupo }} <br/></span>

		        <v-divider class="my-2"></v-divider>
		        <label>Promociones</label>
		        <v-autocomplete
	            :items="promociones"
	            v-model="promocion"
	            label="Promoción"
	            dense
	            filled
	            item-value="idpromociones"
	            item-text="nombre_promocion"
	            class="ma-2"
	            hide-details
	            clearable
	          ></v-autocomplete>

		        <v-divider class="my-2"></v-divider>

		        <label><b>Datos generales del pago</b></label>
						<v-list class="transparent" dense>
				      <v-list-item class="pa-0" v-if="!promocion">
				        <v-list-item-title align="end" class="pr-2">
		              Costo del curso:
		            </v-list-item-title>

				        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
				          <b>$ {{ precio_inicial }}</b>
				        </v-list-item-subtitle>
				      </v-list-item>
		          <v-divider v-if="!promocion"></v-divider>

				      <v-list-item class="pa-0" v-if="descuento && !promocion">
				        <v-list-item-title align="end" class="pr-2">
		              Desc. pronto pago:
		            </v-list-item-title>

				        <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
				          <b>$ {{ descuento }}</b>
				        </v-list-item-subtitle>
				      </v-list-item>
		          <v-divider v-if="descuento && !promocion"></v-divider>

		          <v-list-item class="pa-0" v-if="pago_anterior">
		            <v-list-item-title align="end" class="pr-2">
		              Pago anterior:
		            </v-list-item-title>
		            
		            <v-list-item-subtitle class="black--text font-weight-semibold text-body-1">
		              <b>$ {{ pago_anterior }}</b>
		            </v-list-item-subtitle>
		          </v-list-item>
		          <v-divider v-if="pago_anterior"></v-divider>

		          <v-list-item class="pa-0" v-if="solcitaFactura">
		            <v-list-item-title align="end" class="pr-2">
		              <b>Subtotal:</b>
		            </v-list-item-title>
		            
		            <v-list-item-subtitle class="text-h6 black--text">
		              <b>$ {{ parseFloat( total_a_pagar ) }}</b>
		            </v-list-item-subtitle>
		          </v-list-item>
		          <v-divider v-if="solcitaFactura"></v-divider>

		          <v-list-item class="pa-0" v-if="solcitaFactura">
		            <v-list-item-title align="end" class="pr-2">
		              <b>IVA:</b>
		            </v-list-item-title>
		            
		            <v-list-item-subtitle class="text-h6 black--text">
		              <b>$ {{ ( parseFloat( total_a_pagar ) * .16 ) }}</b>
		            </v-list-item-subtitle>
		          </v-list-item>
		          <v-divider v-if="solcitaFactura"></v-divider>

				      <v-list-item class="pa-0">
				        <v-list-item-title align="end" class="pr-2">
		              <b>Total a pagar:</b>
		            </v-list-item-title>
				        
		            <v-list-item-subtitle class="text-h6 black--text">
				          <b>$ {{ pagoFinal }}</b>
				        </v-list-item-subtitle>
				      </v-list-item>
		          <v-divider></v-divider>

				      <v-list-item class="pa-0">
				        <v-list-item-title align="end" class="pr-2">
		              <b>Total pagado:</b>
		            </v-list-item-title>

				        <v-list-item-subtitle class="text-h4 black--text" v-if="tipo_pago != 1">
		              <v-text-field
		                v-if="vistaPrevia"
		                dense
		                type="number"
		                v-model="total_pagado"
		              ></v-text-field>
		            </v-list-item-subtitle>

				        <v-list-item-subtitle class="text-h4 black--text" v-else>
				          <v-text-field
				            dense
				            type="number"
				            v-model="total_pagado"
				          ></v-text-field>
				        </v-list-item-subtitle>
				      </v-list-item>
		          <v-divider></v-divider>
				    </v-list>
      		</v-card-text>
      	</v-card>
			</v-col>

	    <v-divider class="my-4"></v-divider>
	    <v-row>
	    	<v-col cols="12" class="px-8">
	    		<v-card class="shadowCard">
	    		  <v-card-text>
	    		    <v-row>
								<v-col cols="12" md="6">
									<label><b>Selecciona la forma de pago</b></label>

									<v-radio-group
							      v-model="tipo_pago"
							      column
							    >
							      <v-radio
							      	v-for="(item, i) in tipos_pago"
							      	:key="i"
							        :label="item.tipo"
							        :value="item.id"
							      ></v-radio>
							    </v-radio-group>


							    <!-- SOLICITAR FACTURA -->
					        <div v-if=" ( ((parseFloat(total_a_pagar) - parseFloat(pago_anterior)  - parseFloat(descuentoHermanos)  ) + parseFloat(saldoFactura)) - total_pagado ) <= 0 || solcitaFactura">
					          <v-checkbox 
					            label="Con Factura" 
					            v-model="solcitaFactura" 
					            :value="1"
					            small
					            dense
					          ></v-checkbox>
					        </div>

							    <div>
							    	<v-btn 
					            color="green" 
					            dark 
					            small 
					            class="mr-2 text-capitalize" 
					            tile
					            v-if=" ( pagoFinal - total_pagado ) <= 0 "
					          >
					            <v-icon small left>mdi-check-circle</v-icon>
					            Liquidado
					          </v-btn>
							    	
					          <v-btn color="green" dark small class="text-capitalize" v-if="beca">Beca del {{ beca.becaTotal }}% </v-btn>
							    </div>

							    <v-btn 
					          color="orange"
					          dark
					          class="mb-6 mt-2"
					          tile
					          small
					          @click="getPrecioPagar()"
					          v-if="[28,334].includes( getdatosUsuario.iderp ) "
					        >
					          <v-icon left small>mdi-cash-lock-open</v-icon>
					          Calcular costo
					        </v-btn>

								</v-col>

								<v-col cols="12" md="6" v-if="tipo_pago && tipo_pago != 1">
					        <v-card-text align="center" class="pa-0">
					          <div v-if="!file">
					            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
					              <div class="dropZone-info" @drag="onChange">
					                <span class="fa fa-cloud-upload dropZone-title"></span>
					                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
					                <div class="dropZone-upload-limit-info">
					                  <div>Extensión: png, jpg, jpeg, svg</div>
					                  <div>Tamaño máximo: 10 MB</div>
					                </div>
					              </div>
					              <input type="file" @change="onChange">
					            </div>
					          </div>

					          <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
					          <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>
					        </v-card-text>

								</v-col>
	    		    </v-row>
	    		  </v-card-text>
	    		</v-card>
	    	</v-col>
	    </v-row>

		</v-row>

    <v-row v-if="!( pagoFinal - parseFloat(total_pagado)  >= -5  )">
      <v-col cols="12">
        <v-alert
          dense
          outlined
          type="error"
        >
          No puedes agregar un pago mayor al adeudo, el restante se debe ingresar en el siguiente grupo
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="promocion">
    	<v-col cols="12" class="px-8">
    		<v-card class="shadowCard">
    			<v-alert
            text
            color="info"
          >
            <h3 class="text-h6">
              HOJA DE PROCEDIMIENTO DE VALIDACION DE PROMOCIONES PARA COMISIONES
            </h3>
            <br/>
            <div>
              La hoja de términos y condiciones la podrá  descargar en el apartado de DOCS 2025 con el nombre de "Términos y condiciones de promoción" para que el cliente pueda firmarla , recuerde que si la hoja no esta Ingresada de manera correcta en sistema NO SE PODRA LIBERAR SU COMISIÓN
            </div>
          </v-alert>
    		</v-card>
    	</v-col>
    </v-row>

    <v-row>
    	<v-col cols="12" md="6" class="px-8" v-if="promocion">
    		<v-card class="shadowCard">
    			<v-card-title class="text-subtitle-1">
    				Sube la imagen de la INE
    				<br/>
    				Puedes usar un ctrl + v
    			</v-card-title>
    			<v-card-text>
    			  <v-file-input
					    label="File input"
					    filled
					    prepend-icon="mdi-camera"
					    v-model="img_ineArchivo"
					    @change="updateFotoFormatos( img_ineArchivo, 1 )"
					  ></v-file-input>

	          <v-img :src="img_ine.url" v-if="img_ine" contain max-height="300"/>

	          <v-btn 
	          	v-if="img_ine"
	          	color="red" 
	          	dark 
	          	small 
	          	tile 
	          	@click="img_ine.file = null, img_ine.url = null"
	          	class="mt-4"
	          >
	          	<v-icon small left>mdi-delete</v-icon>
	        		Eliminar
	        	</v-btn>
    			</v-card-text>
    		</v-card>
    	</v-col>

    	<v-col cols="12" md="6" class="px-8" v-if="promocion">
    		<v-card class="shadowCard">
    			<v-card-title class="text-subtitle-1">
    				Sube la imagen del Formato Firmado
    				<br/>
    				Puedes usar un ctrl + v
    			</v-card-title>
    			<v-card-text>
    			  <v-file-input
					    label="File input"
					    filled
					    prepend-icon="mdi-camera"
					    v-model="img_formatoArchivo"
					    @change="updateFotoFormatos( img_formatoArchivo, 2 )"
					  ></v-file-input>

	          <v-img :src="img_formato.url" v-if="img_formato" contain max-height="300"/>

	          <v-btn 
	          	v-if="img_formato"
	          	color="red" 
	          	dark 
	          	small 
	          	tile 
	          	@click="img_formato.file = null, img_formato.url = null"
	          	class="mt-4"
	          >
	          	<v-icon small left>mdi-delete</v-icon>
	        		Eliminar
	        	</v-btn>

    			</v-card-text>
    		</v-card>
    	</v-col>

    </v-row>

		<v-card-actions class="pb-0 mt-6">
      <v-btn
        color="black"
        dark
        @click="steppers.paso = 2"
        tile
        small
        class="text-capitalize"
      >
        <v-icon left small>mdi-chevron-double-left</v-icon>
        regresar
      </v-btn>
      <v-spacer></v-spacer>

      <!-- Botón de procesar -->
      <v-btn
        color="green"
        small
        dark
        class="text-capitalize"
        @click="procesarPago()"
        tile
        v-if="!noPago && ( pagoFinal- parseFloat(total_pagado) ) >= 0"
        :disabled="cargar"
      >
        <v-icon left small>mdi-cash-check</v-icon>
        Procesar
      </v-btn>
    </v-card-actions>

    <!-- DIALOGO PARA CONFIRMAR EL CORREO DEL ALUMNO -->
    <v-dialog
      v-model="dialogConfirmaCorreo"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Correo a donde se enviará el recibo de pago
        </v-card-title>
        <v-card-text>
          <v-text-field
            name="name"
            label="label"
            id="id"
            v-model="alumno.email"
            filled
            v-if="alumno"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            @click="dialogConfirmaCorreo = false, $emit('terminarProceso')"
            tile
            dark
            small
          >
            <v-icon left small>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="enviarComprobante()"
            tile
            dark
            small
          >
            <v-icon left small>mdi-content-save</v-icon>
            Actualizar y enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGO PARA CONFIRMAR EL CORREO DEL ALUMNO -->
    <v-dialog
      v-model="dialogSiguientepago"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Deseas realizar el pago del siguiente hermano? 
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            @click="cancelar()"
            rounded
            dark
            small
          >
            NO
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="dialogSiguientepago = false"
            rounded
            dark
            small
          >
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import leerImagenes    from '@/mixins/leerImagenes'

  const convertir = require('numero-a-letras');
  import { jsPDF } from "jspdf";


	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, leerImagenes ],

  	props:[
			'hermanos',
			'steppers',
			'tutor',
			'alumno',
			'dialogAddAlumno',
			'img_ine',
			'img_formato'
	  ],

    data: () => ({
    	escuela: 2,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      precio_inicial:0,
			descuento:0,
			total_a_pagar:0,

			tipo_pago:null,
			sucursal:null,
			total_pagado:0,

			label:'',
      archivos:null,
      vistaPrevia: null,
      textoImagen:'',
      monto:0,
      isPracticaja: false,
      isTransferencia:false,
      isSeven: false,
      isOXXO: false,
      isWeb:false,
      isTarjeta:false,
      isSantander: false,
      isDirectoBancoBBVA: false,
      isDirectoBANREGIO: false,
      file: '',
      dragging: false,
      pago_anterior:0,
      banco:'',

      texto_completo:'',

      saldoFavor: 0,
      tieneSaldoFavor: false,

      fechapago: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      tipos_pago:[
      	{ id: 1, tipo: 'EFECTIVO'},
      	{ id: 2, tipo: 'DEPOSITO / TRANSFERENCIA'},
      	{ id: 4, tipo: 'TARJETA' },
      ],

      dialogConfirmaCorreo:'',

      beca: null,
      pagoRealizao:0,

      pagosCursos:[],

      myState2: {
        pdf:         null,
        currentPage: 1,
        zoom:        2
      },
      solcitaFactura:0,
      saldoFactura: 0,
      descuentoRecomendados:[],
      descRecomienda:0,
      descuentosEspeciales:[],
      noPago:false,
      posicionHermano: 0,
      descuentoHermanos: 0,
      dialogSiguientepago: false,
      promociones:[],
      promocion: null,
      aut_clave_rastreo:null,
			folio_operacion:null,
			cuenta:null,
			img_ineArchivo: null,
			img_formatoArchivo: null,
    }),

    computed: {

    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
      
    	extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      },

      adeudo( ){
        const restante = this.pagoFinal - parseFloat(this.total_pagado)
        if( restante > 0 )
          return restante.toFixed(2)
        else
          return 0
      },

      columnas( ){

      	return ( 12 / this.hermanos.length  ).toFixed(0)

      },


      pagoFinal( ){


      	let pagar = 0

      	if( this.promocion ){

      		const existePromocion = this.promociones.find( el => el.idpromociones == this.promocion )

      		pagar = existePromocion ? existePromocion.costo : 0

      	}else{
      		
      		pagar = (parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior) - parseFloat(this.descuentoHermanos) + parseFloat(this.saldoFactura))

      	}

      	return pagar 


      }
    },

    watch: {
      solcitaFactura ( val ){
        if( val ){
          this.saldoFactura = ((parseFloat(this.total_a_pagar)  - parseFloat(this.descuentoHermanos)  - parseFloat( this.saldoFavor )) *.16).toFixed(2)

          let grupo = this.alumno.grupo

          if( grupo.factura ){
            this.saldoFactura = ( (parseFloat(this.precio_inicial) - parseFloat(this.descuento) - parseFloat(descuentoHermanos) ) * .16).toFixed(2)
          }

        }else{ this.saldoFactura = 0 }

      },

      total_a_pagar( ){
        if( this.saldoFactura ){
          this.saldoFactura = ( this.total_a_pagar * .16 ).toFixed(2)
        }else{ this.saldoFactura = 0 }
      },

    	// grupo ( ){
    	// 	if( this.grupo ){ this.getPrecioPagar( ) }
    	// },

      archivos( val ){
        this.total_pagado       = 0
        this.isPracticaja       = false
        this.isTransferencia    = false
        this.isSeven            = false
        this.isOXXO             = false
        this.isWeb              = false
        this.isTarjeta          = false
        this.isDirectoBancoBBVA = false
        this.isDirectoBANREGIO  = false
        if( !val ){ this.vistaPrevia = null } 
      },

      tipo_pago( val ){
        this.total_pagado = 0
      },

      posicionHermano( ){
        this.getPrecioPagar()
      },

    },

    async created () {
    	await this.getPrecioPagar( )
    	await this.getPromociones( )
    },


    methods: {
      getPrecioPagar( ) {

      	this.cargar = true

      	const payload = {
      		id_alumno:        this.alumno.id_alumno,
      		id_empleado:      null,
      		tipoAlumno:       1,
      		empleado_activo:  0,
      		id_curso:         this.alumno.grupo.id_curso,
      		grupoSiguiente:   this.alumno.grupo,
          hermanos:         false,
          fechapago:        this.fechapago,
          factura:          this.alumno.grupo.factura,
          unidad_negocio:   this.alumno.unidad_negocio,
          matricula:        this.alumno.matricula
      	}


        return this.$http.post('inscripciones.calcular.precio', payload ).then(response=>{

        	this.precio_inicial         = response.data.precio_inicial
					this.descuento              = response.data.descuento
					this.total_a_pagar          = response.data.total_a_pagar
					this.beca                   = response.data.beca 
          this.pago_anterior          = response.data.pago_anterior 
          this.saldoFavor             = response.data.saldoFavor 
          this.tieneSaldoFavor        = response.data.tieneSaldoFavor 
          this.pagosCursos            = response.data.pagosCursos
          this.descuentoRecomendados  = response.data.descuentoRecomendados
          this.descRecomienda         = response.data.descRecomienda
          this.descuentosEspeciales   = response.data.descuentosEspeciales
          this.descuentoHermanos      = response.data.descuentoHermanos

        	this.cargar = false
          this.noPago = false
        }).catch( error =>{
          this.noPago = true
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPromociones( ) {

      	this.cargar = true

        return this.$http.get('promociones.list' ).then(response=>{

        	this.promociones = response.data.filter( el => el.escuela == this.getEscuela && !el.promocion.match('Hermanos') )

        	// if( !this.alumno.grupo.grupo.match('CICLO 12') ){
        		this.promociones = this.promociones.filter( el => !el.promocion.match('Promo') )
        	// }


        	if( this.alumno.grupo.grupo.match('TEENS') ){

        		this.promociones = this.promociones.filter( el => el.promocion.match('TEENS') )

        	}else{
        		this.promociones = this.promociones.filter( el => !el.promocion.match('TEENS') )
        	}

        	if( this.alumno.grupo.grupo.match('ONLINE') ){

        		this.promociones = this.promociones.filter( el => el.promocion.match('Online') )

        	}else{
        		this.promociones = this.promociones.filter( el => !el.promocion.match('Online') )
        	}


        	if( this.alumno.grupo.grupo.match('SABATINO') ){

        		this.promociones = this.promociones.filter( el => el.promocion.match('Sabatino') )

        	}else{

        		this.promociones = this.promociones.filter( el => !el.promocion.match('Sabatino') )

        	}


        	this.cargar = false
          this.noPago = false
        }).catch( error =>{
          this.noPago = true
          alert('error')
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      seleccionarAlumno( value ){

        let payload = {
          grupo: value, 
          hermanos: false
        }

      	this.$emit('alumnoSeleccionado', value )
      },

      realizarPago( data ){
        return new Promise((resolve,reject)=>{
          this.$http.post('registrar.grupo.alumno', data ).then( response => {
            // Convertir el monto en texto

            this.url = axios.defaults.baseURL + 'recibo-pago/' + response.data.ingreso.id + '.pdf'

            window.open( this.url )

            resolve('Inscrito correctamente')
          }).catch( error =>{
            reject( error )
          }).finally( () => { this.cargar = false })
        })
      },

      grabarImagen( ){
        return new Promise((resolve,reject)=>{
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo
          formData.append("file", this.file);

          this.$http.post("grabar.comprobante", formData).then( response => {
            resolve( response.data ) 
          }).catch( error =>{
            reject( error )
          }).finally( () => { this.cargar = false })
        })
      },


      grabarImagenFormatos( file ){
        return new Promise((resolve,reject)=>{
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo
          formData.append("file", file);

          this.$http.post("grabar.comprobante", formData).then( response => {
            resolve( response.data ) 
          }).catch( error =>{
            reject( error )
          }).finally( () => { this.cargar = false })
        })
      },

      async procesarPago( ){
      	this.cargar = true

      	let name_ine = ''
      	let name_formato = ''

      	if( this.promocion ){

      		name_ine = await this.grabarImagenFormatos( this.img_ine.file ).then( response => response ).catch( error => { return this.validarError(error) })
      		name_ine = name_ine.nombre

      		name_formato = await this.grabarImagenFormatos( this.img_formato.file ).then( response => response ).catch( error => { return this.validarError(error) })
      		name_formato = name_formato.nombre

      	}

      	// Nombre del archivo que se guardo 
      	let nombre_archivo = {nombre:''}

      	// Solo es es imagen, si no, se va vacío
      	if( this.tipo_pago != 1 ){
      		nombre_archivo = await this.grabarImagen( ).then( response => response ).catch( error => { return this.validarError(error) })
      	}

      	// El curso se debe guardar por que es NI
        let guardarCurso = true

        // Limpiar la cadena de cáracteres
        this.texto_completo = this.texto_completo.replace(/[∀,∂,∃,∄,∅,∆,∇,∈,∉,∊,∋,∌,∍,∎,∏,∐,∑,∓,∔,∖,√,∛,∜,∝,∞,∟,∠,∡,∢,∣,∤,∥,∦,∧,∨,∩,∪,∫,∬,∭,∮,∯,∰,∱,∲,∳,∴,∵,∶,∷,∸,∹,∺,∻,∼,∽,∾,∿,≀,≁,≂,≃,≄,≅,≆,≇,≈,≉,≊,≋,≌,≍,≎,≏,≐,≑,≒,≓,≔,≕,≖,≗,≘,≙,≚,≛,≜,≝,≞,≟,≠,≡,≢,≣,≤,≥,≦,≧,≨,≩,≪,≫,≬,≭,≮,≯,≰,≱,≲,≳,≴,≵,≶,≷,≸,≹,≺,≻,≼,≽,≾,≿,•,ЗУЗ,З,У,ф,₴,#,*,:,$,.,/]/g,' ')

        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};

        this.texto_completo  = this.texto_completo.split('').map( letra => acentos[letra] || letra).join('').toString();

        /**/

        const existePromocion = this.promociones.find( el => el.idpromociones == this.promocion )


      	const payload = {
          adeudo                     : parseFloat(this.adeudo),
			    base_path                  : null,
			    comentarios                : '',
          descRecomienda             : this.descRecomienda,
			    descuento                  : this.descuento - parseFloat(this.descuentoHermanos) ,
          descuentoRecomendados      : this.descuentoRecomendados,
          descuentosEspeciales       : this.descuentosEspeciales,
          grupo                      : null,// estos se deben agregar más adelante
          guardarCurso,
			    id_alumno                  : this.alumno.id_alumno,
			    id_ciclo                   : null,// estos se deben agregar más adelante
          id_curso                   : null,// estos se deben agregar más adelante
			    id_descuento_pronto_pago   : null,
			    id_forma_pago              : this.tipo_pago,
          id_grupo                   : null,// estos se deben agregar más adelante
			    id_plantel_ingreso         : this.getdatosUsuario.id_plantel,
			    id_tipo_descuento          : null,
			    id_tipo_ingreso            : 2,
			    id_tipo_inscripcion        : this.total_pagado >= this.total_a_pagar ? 2 : 3,
			    id_usuario_ultimo_cambio   : this.getdatosUsuario.iderp,
          idbecas                    : this.beca ? this.beca.id : 0,
			    monto_adeudo_grupo         : 0,
			    monto_descuento_grupo      : parseFloat( this.descuento ) - parseFloat(this.descuentoHermanos),
			    monto_pagado_total         : parseFloat(this.total_pagado ),
			    monto_saldo_favor          : 0,
          nuevoSaldoFavor            : 0,
			    pago_completado_sn         : this.total_pagado >= (parseFloat(this.total_a_pagar) - parseFloat(this.pago_anterior)) ? 1 : 0,
			    precio_curso               : this.precio_inicial,
			    precio_grupo_con_descuento : parseFloat( this.total_a_pagar) - parseFloat(this.descuentoHermanos) + parseFloat( this.saldoFactura ),
			    precio_grupo_sin_descuento : this.precio_inicial,
          saldoFavor                 : 0,
          solcitaFactura             : this.solcitaFactura,
          tieneSaldoFavor            : this.tieneSaldoFavor,
          texto_completo             : this.texto_completo,
          tipoAlumno                 : 1,
          total_pagado_alumno        : (parseFloat(this.precio_inicial) + parseFloat(this.saldoFactura)),
			    url_foto                   : nombre_archivo.nombre,
          pago_final_grupo           : parseFloat( this.pago_anterior ) + parseFloat(this.total_pagado ),
          hermanos                   : [this.alumno],
          tutor                      : this.tutor,
          unidad_negocio             : this.getEscuela,
          promocion                  : existePromocion ? existePromocion : null,
          name_ine,
					name_formato

				}


				this.$http.post('registrar.grupo.alumno.unicos', payload ).then( response => {
          console.log( response.data )
          this.cargar = false
          // Convertir el monto en texto
	          // this.noPago = true

          for( const i in response.data ){
	          let url = axios.defaults.baseURL + 'recibo-pago/' + response.data[i].id + '.pdf'
	          window.open(url, '_blank');
          }

          this.validarSuccess('Pagos realizados correctamente')
          this.dialogAddAlumno.estatus = false
          this.pagoRealizao = response.data.ingreso.id
          // this.dialogConfirmaCorreo = true

				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
      },

      enviarComprobante( ){

        let grupo = this.grupo

        if( this.grupo.length ){
          grupo = this.grupo[this.posicionHermano]
        }

        const payload = {
          folio:                  this.pagoRealizao,
          alumno:                 this.alumno.alumno,
          matricula:              this.alumno.matricula,
          plantel:                this.getdatosUsuario.plantel,
          ciclo:                  grupo.grupoSiguiente.ciclo,
          curso:                  grupo.grupoSiguiente.curso,
          cantidad_recibida:      this.total_pagado,
          cantidad_letra:         convertir.NumerosALetras(this.total_pagado).toUpperCase(),
          descuento_pronto_pago:  this.descuento,
          adeudo:                 this.adeudo,
          saldo_favor:            this.nuevoSaldoFavor,
          id_alumno:              this.alumno.id_alumno,
          email:                  this.alumno.email,
          unidad_negocio:         this.alumno.unidad_negocio,
          fecha_pago:             this.fechapago,
          recepcion:              this.getdatosUsuario.nombre_completo,
          solcitaFactura:         this.solcitaFactura
        }

        this.cargar = true
        this.$http.post('actualizar.correo.alumno', payload ).then( response => {

          this.validarSuccess(response.data.message)

          if( this.grupo.length){
            this.grupo[this.posicionHermano]['pagado'] = true
            this.dialogConfirmaCorreo = false
            this.dialogSiguientepago = true
            this.total_pagado = 0
            this.getPrecioPagar()
          }else if( !this.grupo.length ){
            this.validarSuccess(response.data.message)
            this.$emit('terminarProceso')
          }

        }).catch( error =>{
         this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cancelar( ){
        this.dialogSiguientepago = false
        this.$emit('terminarProceso')
      },

      updateFotoFormatos( file, imeformato ){


      	if( file  ){


      		if( imeformato == 1 ){
      			this.img_ine.url        = null
	          this.img_ine.formData   = null 
	          this.img_ine.image_name = null
	          this.img_ine.file       = null
      		}

      		if( imeformato == 2 ){
      			this.img_formato.url        = null
	          this.img_formato.formData   = null 
	          this.img_formato.image_name = null
	          this.img_formato.file       = null
      		}


	        if (file.size > 10000000) {
	          alert("please check file size no over 5 MB.");
	          return;
	        }

	        console.log( 'archivooooo',file )

	        if( !this.img_ine || ( this.img_ine && !this.img_ine.file ) ){

	          this.updateFotoIne( file )

	        }else if( !this.img_formato || ( this.img_formato && !this.img_formato.file ) ){

	          this.updateFotoFormato( file )

	        }
      	}


      },

      updateFotoIne( file ){

        // Verificar que el archivo existe
        if ( !file ) {
          this.validarErrorDirecto("No se ha seleccionado un archivo.");
          return;
        }

        // Crear una instancia de FormData
        let formData = new FormData();
        formData.append('file',  file );

        // Crear vista previa utilizando FileReader
        const reader = new FileReader();
        reader.readAsDataURL( file ); // Acceder correctamente al archivo desde this.img_ine.file

        reader.onload = () => {
          this.img_ine.url        = reader.result
          this.img_ine.formData   = formData 
          this.img_ine.image_name = file.name
          this.img_ine.file       = file
        };
      },

      updateFotoFormato( file ){

        // Verificar que el archivo existe
        if (!file) {
          this.validarErrorDirecto("No se ha seleccionado un archivo.");
          return;
        }

        // Crear una instancia de FormData
        let formData = new FormData();
        formData.append('file', file);

        // Crear vista previa utilizando FileReader
        const reader = new FileReader();
        reader.readAsDataURL(file); // Acceder correctamente al archivo desde this.img_formato.file

        reader.onload = () => {
        	this.img_formato.url        = reader.result
          this.img_formato.formData   = formData 
          this.img_formato.image_name = file.name
          this.img_formato.file       = file
        };
      },

      

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>
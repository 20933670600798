import '@/plugins/vue-composition-api'
// import '@/styles/styles.scss'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueResource from "vue-resource";
import config from 'dotenv';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';

import 'vue-organization-chart/dist/orgchart.css'

import AudioRecorder from 'vue-audio-recorder'

import axios from 'axios';

config.config();
Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(Croppa);
Vue.use(AudioRecorder)


if(process.env.NODE_ENV == 'development'){
  // Vue.http.options.root = 'http://localhost:3004/';
  axios.defaults.baseURL = 'http://localhost:3004/';
  // axios.defaults.baseURL = 'https://escuelakpi.club/kpi/';
}else{
  // Vue.http.options.root = process.env.VUE_APP_RUTA_API;
  axios.defaults.baseURL = process.env.VUE_APP_RUTA_API;
}

// axios.interceptors.request.use(config => config);
const datosUsuario = store.state.login.datosUsuario.iderp;

axios.interceptors.request.use(config => {
  // Agregar encabezados personalizados (por ejemplo, token de autorización)
  config.headers.Authorization = 'Bearer ' + localStorage.tlaKey;
  config.headers.Accept = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
  config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
  config.headers['user-iD'] = datosUsuario;

  return config;
}, error => {
  // Manejar errores de solicitud
  console.log( error )
  return Promise.reject(error);
});

Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  vuetify,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");




<template>
  <v-container >
    <v-dialog
      v-model="dialogAddAlumno.estatus"
      persistent
      max-width="800"
      @keydown.ctrl="pegar"
    >
      <v-card >
        <v-stepper alt-labels v-model="steppers.paso">
		      <v-stepper-header>
		        <v-stepper-step
		        	:complete="steppers.paso > 1"
        			step="1"
        		>
		          Datos generales
		        </v-stepper-step>

		        <v-divider></v-divider>

		        <v-stepper-step
		        	:complete="steppers.paso > 2"
		        	step="2"
		        >
		          Seleccionar grupo
		        </v-stepper-step>

		        <v-divider></v-divider>

		        <v-stepper-step 
		        	:complete="steppers.paso > 3"
		        	step="3"
		        >
		          Realizar pago
		        </v-stepper-step>
		      </v-stepper-header>

		      <v-stepper-items>

		      	<!-- Datos generales del alumno -->
			      <v-stepper-content step="1">
			        <v-card>
			        	<NuevoAlumno
			        		:infoAlumno="infoAlumno"
			        		:steppers="steppers"
                  :hermanos="hermanos"
			        		:alumno="alumno"
			        		:dialogAddAlumno="dialogAddAlumno"
			        		:tutor="tutor"
                  :seleccionTipoAlumno="seleccionTipoAlumno"
			        		v-if="steppers.paso == 1"
			        	/>
			        </v-card>
			      </v-stepper-content>

			      <v-stepper-content step="2">
			        <v-card>

                <!-- Agregar grupo a nuevos hermanos -->
			        	<SeleccionaGrupoHermanos
			        		:infoAlumno="infoAlumno"
			        		:steppers="steppers"
			        		:hermanos="hermanos"
			        		:dialogAddAlumno="dialogAddAlumno"
			        		:tutor="tutor"
                  :alumno="alumno"
			        		v-if="steppers.paso == 2 && seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 2 "
			        	/>

                <!-- Agregar grupo a nuevo alumno -->
                <SeleccionaGrupoAlumno
                  :infoAlumno="infoAlumno"
                  :steppers="steppers"
                  :hermanos="hermanos"
                  :dialogAddAlumno="dialogAddAlumno"
                  :tutor="tutor"
                  :alumno="alumno"
                  v-if="steppers.paso == 2 && seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 1 "
                />

                <!-- agregar grupo a hermnaos RI -->
                <SeleccionaGrupoHermanosRI
                  :infoAlumno="infoAlumno"
                  :steppers="steppers"
                  :hermanos="hermanos"
                  :dialogAddAlumno="dialogAddAlumno"
                  :tutor="tutor"
                  :alumno="alumno"
                  v-if="steppers.paso == 2 && seleccionTipoAlumno.opTipoAlumno == 1 && seleccionTipoAlumno.opAlumno == 2 "
                />

                <!-- agregar grupo a alumno RI -->
                <SeleccionaGrupoAlumnoRI
                  :infoAlumno="infoAlumno"
                  :steppers="steppers"
                  :hermanos="hermanos"
                  :dialogAddAlumno="dialogAddAlumno"
                  :tutor="tutor"
                  :alumno="alumno"
                  v-if="steppers.paso == 2 && seleccionTipoAlumno.opTipoAlumno == 1 && seleccionTipoAlumno.opAlumno == 1 "
                />

			        </v-card>
			      </v-stepper-content>

			      <v-stepper-content step="3">
			        <v-card>

                <!-- Agregar Pago a nuevos hermanos -->
			        	<RealizarPagoHermanos
			        		:infoAlumno="infoAlumno"
			        		:steppers="steppers"
			        		:hermanos="hermanos"
			        		:tutor="tutor"
			        		:dialogAddAlumno="dialogAddAlumno"
			        		v-if="steppers.paso == 3 && seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 2 "
			        	/>

                <!-- Agregar Pago a nuevo alumno -->
                <RealizarPagoAlumno
                  :infoAlumno="infoAlumno"
                  :steppers="steppers"
                  :hermanos="hermanos"
                  :alumno="alumno"
                  :tutor="tutor"
                  :dialogAddAlumno="dialogAddAlumno"
                  v-if="steppers.paso == 3 && seleccionTipoAlumno.opTipoAlumno == 2 && seleccionTipoAlumno.opAlumno == 1 "
                  :img_ine="img_ine"
                  :img_formato="img_formato"
                />

                <!-- agregar Pago a hermnaos RI -->
                <RealizarPagoHermanosRI
                  :infoAlumno="infoAlumno"
                  :steppers="steppers"
                  :hermanos="hermanos"
                  :tutor="tutor"
                  :dialogAddAlumno="dialogAddAlumno"
                  v-if="steppers.paso == 3 && seleccionTipoAlumno.opTipoAlumno == 1 && seleccionTipoAlumno.opAlumno == 2 "
                />

                <!-- agregar Pago a alumno RI -->
                <RealizarPagoAlumnoRI
                  :infoAlumno="infoAlumno"
                  :steppers="steppers"
                  :hermanos="hermanos"
                  :alumno="alumno"
                  :tutor="tutor"
                  :dialogAddAlumno="dialogAddAlumno"
                  v-if="steppers.paso == 3 && seleccionTipoAlumno.opTipoAlumno == 1 && seleccionTipoAlumno.opAlumno == 1 "
                />

			        </v-card>
			      </v-stepper-content>
			    </v-stepper-items>

		    </v-stepper>
      </v-card>
    </v-dialog>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  import axios from 'axios';
  var moment = require('moment');
  moment.locale();

  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'
  import { Clipboard } from "@capacitor/clipboard";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import dialogHistorial       from '@/components/cambios/dialogHistorial.vue';
  import dialogRechazar        from '@/components/cambios/dialogRechazar.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  import NuevoAlumno             from '@/components/inscripciones/steppers/NuevoAlumno.vue'
  import SeleccionaGrupoHermanosRI from '@/components/inscripciones/steppers/SeleccionaGrupoHermanosRI.vue'
  import SeleccionaGrupoHermanos   from '@/components/inscripciones/steppers/SeleccionaGrupoHermanos.vue'
  import SeleccionaGrupoAlumno     from '@/components/inscripciones/steppers/SeleccionaGrupoAlumno.vue'
  import SeleccionaGrupoAlumnoRI   from '@/components/inscripciones/steppers/SeleccionaGrupoAlumnoRI.vue'
  import RealizarPagoHermanos      from '@/components/inscripciones/steppers/RealizarPagoHermanos.vue'
  import RealizarPagoHermanosRI    from '@/components/inscripciones/steppers/RealizarPagoHermanosRI.vue'
  import RealizarPagoAlumno        from '@/components/inscripciones/steppers/RealizarPagoAlumno.vue'
  import RealizarPagoAlumnoRI      from '@/components/inscripciones/steppers/RealizarPagoAlumnoRI.vue'


  export default {

    components:{
      Alerta,
      carga,
      dialogHistorial,
      dialogRechazar,
      NuevoAlumno,
      SeleccionaGrupoHermanos,
      RealizarPagoHermanos,
      SeleccionaGrupoAlumno,
      RealizarPagoAlumno,
      SeleccionaGrupoAlumnoRI,
      RealizarPagoAlumnoRI,
      SeleccionaGrupoHermanosRI,
      RealizarPagoHermanosRI
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['reporteTexto','dialogAddAlumno'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      paso: 1,

      hermanos:[],

      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      dialogUpdateCambio: false,
      alumno:{
        nombre:'',
        apellido_paterno:'',
        apellido_materno:'',
        telefono:'',
        celular:'',
        id_tipo_alumno:'1',
        id_usuario_ultimo_cambio:0,
        matricula:'',
        edad:'',
        ocupacion:'Estudiante',
        id_contacto:0,
        unidad_negocio:0,
        id_prospecto:null,
        id_empleado: null,
        tutor: null,
        matricula_erp:'',
        iderp:0,
        grupo: null
      },
      dialogSuccess:false,
      successMensaje:'',
      dialogRechazar:false,
      mensajeRechazo:'',
      dialogMensajes: false,
      mensajes:[],
      dialogAddCambios: false,

      teachers: [],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      dialogAddCambio:false,
      loading:        false,
      disabled:       false,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      url:'',

      imagen: null,
      dialogImagen: false,
      

      paramsHistorial: {
        estatus: false
      },

      paramsRechazo: {
        estatus: false
      },

      infoAlumno:{},
      steppers:{
      	paso: 1
      },

      seleccionTipoAlumno:{
        opAlumno:0,
        tipoAlumno:0,
      },

      tutor:{
				nombre:'',
				apellido_paterno:'',
				apellido_materno:'',
				telefono:'',
				celular:'',
				tipo_parentesco:0,
				id_usuario_ultimo_cambio:0
			},

      img_ine: null,
      img_formato: null,

    }),

    watch:{

    	hermanos( val ){ 
    		console.log( val)
    	},

      alumno( val ){
        console.log( val, 'alumno')
      }

    },

    async created () {
      console.clear()

      this.url = axios.defaults.baseURL + 'evidencia-cambios/'
      this.alumno.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp

    },

    methods: {
      updateFotoIne( file ){

        // Verificar que el archivo existe
        if ( !file ) {
          this.validarErrorDirecto("No se ha seleccionado un archivo.");
          return;
        }

        // Crear una instancia de FormData
        let formData = new FormData();
        formData.append('file',  file );

        // Crear vista previa utilizando FileReader
        const reader = new FileReader();
        reader.readAsDataURL( file ); // Acceder correctamente al archivo desde this.img_ine.file

        reader.onload = () => {

          this.img_ine = {
            url        : reader.result,
            formData   : formData ,
            image_name : file.name,
            file       : file
          }
        };
      },

      updateFotoFormato( file ){

        // Verificar que el archivo existe
        if (!file) {
          this.validarErrorDirecto("No se ha seleccionado un archivo.");
          return;
        }

        // Crear una instancia de FormData
        let formData = new FormData();
        formData.append('file', file);

        // Crear vista previa utilizando FileReader
        const reader = new FileReader();
        reader.readAsDataURL(file); // Acceder correctamente al archivo desde this.img_formato.file

        reader.onload = () => {

          this.img_formato = {
            url        : reader.result,
            formData   : formData ,
            image_name : file.name,
            file       : file
          }
        };
      },

      async pegar(event) {
        if (event.code == "KeyV") {
          const { type, value } = await Clipboard.read();

                    // Validamos que sea de tipo imagen
          if (type == "image/png") {
                    // Convertimos el base64 en una imagen
            const file = this.convertFile(value, "imagen.png");
            this.createFile(file);
          }
        }
      },

      convertFile(dataurl, filename) {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      },

      createFile(file) {
        if (file.size > 10000000) {
          alert("please check file size no over 5 MB.");
          return;
        }

        console.log( 'archivooooo',file )

        if( !this.img_ine || ( this.img_ine && !this.img_ine.file ) ){

          this.updateFotoIne( file )

        }else if( !this.img_formato || ( this.img_formato && !this.img_formato.file ) ){

          this.updateFotoFormato( file )

        }
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>